export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'view',
  },
  {
    title: 'Account',
    route: 'pages-account-setting',
    icon: 'UserIcon',
    resource: 'Auth',
    action: 'view',
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'apps-users-list',
    resource: 'User',
    action: 'manage',
  },
  {
    title: 'Clients',
    route: 'pages-clients',
    icon: 'SmileIcon',
    resource: 'Clients',
    action: 'manage',
  },
  {
    title: 'Brands',
    route: 'pages-brands',
    icon: 'ListIcon',
    resource: 'Brand',
    action: 'manage',
  },
  {
    title: 'Collections',
    route: 'pages-collections',
    icon: 'FolderIcon',
    resource: 'Collection',
    action: 'manage',
  },
  {
    title: 'Styles',
    route: 'apps-styles-list',
    icon: 'PackageIcon',
    resource: 'Style',
    action: 'manage',
  },
  {
    title: 'Materials',
    route: 'apps-materials-list',
    icon: 'ScissorsIcon',
    resource: 'Material',
    action: 'manage',
  },
  {
    title: 'Sample Requests',
    route: 'apps-sample-requests-list',
    icon: 'BoxIcon',
    resource: 'SampleRequest',
    action: 'manage',
  },
]
